import {
    Datagrid,
    List,
    TextField,
    DateField
} from "react-admin";
//import { TimestampField } from "../../components/fields";

export const ListCredit = (props) => (
    <List     
        title="Credit"
        // sort={{ field: "createdAt", order: "DESC" }}
        {...props}>
        <Datagrid>
            <TextField source="username" label="Username" />
            <DateField source="createdAt" label="Created" showTime/>
            <TextField label="Method" source="method"/>
            <TextField label="Status" source="status"/>          
            <TextField source="currency" label="Currency" />
            <TextField source="amount" label="Amount" />
        </Datagrid>
    </List>
);