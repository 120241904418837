import Money from "@material-ui/icons/Payment";
import { ListCredit } from "./list";
import { CreateCredit } from "./create";
// import { ShowCredit } from "./show";

export const credit = {
    name: "credit",
    options: {
        label: "Credit"
    },
    icon: Money,
    list: ListCredit,
    create: CreateCredit,
    // show: ShowCredit
};