import React from "react";
import { Show, SimpleShowLayout, TextField, FunctionField } from "react-admin";
import jwtDecode from "jwt-decode";

const decodeAPIToken = (record) => {
  // Decode the token logic here
  // For example, if the token is stored in the "apiToken" field, you can decode it as follows:
  const decodedToken = jwtDecode(record.apiToken); // Use atob() to decode the token
  console.log("**********************************************");
  console.log(decodedToken);
  return JSON.stringify(decodedToken);
};

export const ShowUser = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <FunctionField
        label="API Token"
        render={(record) => <span>{decodeAPIToken(record)}</span>}
      />
      <TextField source="username" />
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="phone" />
    </SimpleShowLayout>
  </Show>
);

// import React from "react";
// import { Show, SimpleShowLayout, TextField } from "react-admin";
// import jwtDecode from "jwt-decode";

// export const ShowUser = (props) => {
//   const { record } = props;

//   // Check if the record and apiToken exist before decoding
//   const decodedToken = record?.apiToken ? jwtDecode(record.apiToken) : "testing";

//   return (
//     <Show {...props}>
//       <SimpleShowLayout>
//         {decodedToken && (
//           <TextField
//             label="Decoded API Token"
//             source="decodedToken"
//             defaultValue={JSON.stringify(decodedToken)}
//           />
//         )}
//         <TextField source="username" />
//         <TextField source="name" />
//         <TextField source="email" />
//         <TextField source="phone" />
//       </SimpleShowLayout>
//     </Show>
//   );
// };

