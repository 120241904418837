export const BulkSendMethods = [
    { id: "SMS", name: "SMS" },
    { id: "Voice", name: "Voice" },
    { id: "Whatsapp", name: "Whatsapp" },
    { id: "Airtime", name: "Airtime" }
];

export const PaymentMethods = [
    { id: "MobileMoney", name: " Mobile Money" },
    { id: "Bank", name: "Bank" },
    { id: "Cash", name: "Cash" },
];