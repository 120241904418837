import UsersIcon from "@material-ui/icons/Group";
// import SettingsIcon from '@mui/icons-material/Settings';
import { ListUsers } from "./list";
import { CreateUser, EditUser } from "./create-edit";
import { ShowUser } from "./show";

export const users = {
    name: "users",
    options: {
        label: "Users"
    },
    icon: UsersIcon,
    // icon: SettingsIcon,
    list: ListUsers,
    create: CreateUser,
    edit: EditUser,
    show: ShowUser
};