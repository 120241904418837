import { stringify } from "qs";
import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
  GET_MANY,
  GET_MANY_REFERENCE,
  HttpError,
} from "react-admin";

const restActions = {
  [CREATE]: create,
  [DELETE]: destroy,
  [DELETE_MANY]: destroyMany,
  [GET_LIST]: getList,
  [GET_MANY]: getMany,
  [GET_MANY_REFERENCE]: getManyReference,
  [GET_ONE]: getOne,
  [UPDATE]: update,
  [UPDATE_MANY]: updateMany,
};

export function restProvider(client) {
  return (type, resource, params) => {
    const getConfig = restActions[type];

    if (!getConfig) {
      return Promise.reject(new HttpError("Unsupported action"));
    }

    const url = `/portal/${resource}`;

    const config = getConfig(url, params);
    
    return client(config)
      .then(response => response?.data)
      .catch(error => {
        if (error.response?.data?.message) {
          return Promise.reject(
            new HttpError(
              error.response.data.message,
              error.response.status,
              error.response.data
            )
          );
        }

        return Promise.reject(new HttpError(error.message));
      });
  };
}

function getList(url, params) {
  return {
    method: "GET",
    url: `${url}?${stringify({
      ...params.pagination,
      ...params.sort,
      filter: params.filter,
    })}`,
  };
}

function getOne(url, params) {
  return { method: "GET", url: `${url}/${params.id}` };
}

function getMany(url, params) {
  const { ids } = params;

  return { method: "GET", url: `${url}?${stringify({ ids })}` };
}

function getManyReference(url, params) {
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  const query = {
    field,
    order,
    page,
    perPage,
    filter: {
      ...params.filter,
      [params.target]: params.id,
    },
  };

  return url !== "/admin/transactions"
    ? { method: "GET", url: `${url}?${stringify(query)}` }
    : { method: "GET", url: `${url}/${params.id}?${stringify(query)}` };
}

function create(url, params) {
  return { method: "POST", url, data: params.data };
}

function update(url, params) {
  return { method: "PATCH", url: `${url}/${params.id}`, data: params.data };
}

function updateMany(url, params) {
  const { ids, data } = params;
  return { method: "PUT", url: `${url}?${stringify({ ids })}`, data };
}

function destroy(url, params) {
  return { method: "DELETE", url: `${url}/${params.id}` };
}

function destroyMany(url, params) {
  const { ids } = params;
  return { method: "DELETE", url: `${url}?${stringify({ ids })}` };
}
