import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_GET_PERMISSIONS
} from "react-admin";
import decodeJwt from "jwt-decode";

const authActions = {
  [AUTH_ERROR]: authError,
  [AUTH_LOGIN]: authLogin,
  [AUTH_LOGOUT]: authLogout,
  [AUTH_CHECK]: authCheck,
  [AUTH_GET_PERMISSIONS]: authPermissions
};

export function authProvider(client) {
  return (type, params) => {
    const action = authActions[type];
    return action(params, client);
    
    // return action(params, client).then((response) => {
    //   // Assuming the response contains the JWT token in the "token" property
    //   const token = response?.token;
    //   console.log(response);
    //   console.log("------------------------------------------------------------");
    //   if (token) {
    //     // Decode the token to get user details
    //     const decodedToken = decodeJwt(token);
    //     // Extract user details from the decoded token
    //     const user = {
    //       id: decodedToken.sub, // Assuming the "sub" claim contains the user ID
    //       username: decodedToken.username, // Assuming the "username" claim contains the username
    //       email: decodedToken.email, // Assuming the "email" claim contains the user's email
    //       role: decodedToken.role,
    //     };
    //     // Return the user object along with the response
    //     return { ...response, user };
    //   }
    //   return response;
    // });
  };
}

function authCheck(_params) {
  const token = localStorage.getItem("token");

  if (!token) {
    return Promise.reject();
  }

  return Promise.resolve();
}

function authError(error) {
  if (error.status === 401) {
    return Promise.reject();
  }

  return Promise.resolve();
}

function authLogin(params, client) {
  const { email, password } = params;
  return client
    .post(`/auth/login`, { email, password })
    .then(response => response.data)
    .then(({ token, role }) => {
      console.log(role);
      console.log("----------------authLogin-------1-------------------------------------");
      const decodedToken = decodeJwt(token);
      console.log(decodedToken.role);
      console.log("------------------authLogin------2------------------------------------");
      localStorage.setItem("token", token);
      localStorage.setItem('permissions', decodedToken.role);
      localStorage.setItem('user_id', decodedToken.id);
    });
}

function authLogout(_params, _client) {
  localStorage.removeItem("token");
  return Promise.resolve();
}

function jsonToken() {
  const token = localStorage.getItem("token");
  const decodedToken = token ? decodeJwt(token) : null;
  return decodedToken;
}

function authPermissions() {
  const user = jsonToken();
  const isSignupOrForgot = /(forgot|signup)/.test(window.location.hash);

  // return user ? Promise.resolve(user) : Promise.reject();

  if (!user && !isSignupOrForgot) {
    return Promise.reject({});
  }

  return Promise.resolve(user || {"role": "guest"});
}
