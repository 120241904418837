import {
    SimpleForm,
    Create,
    TextInput,
    SelectInput,
    Toolbar,
    SaveButton
} from "react-admin";
import { PaymentMethods } from "../../../constants";

const TopUpToolbar = (props) => (
    <Toolbar>
        <SaveButton {...props} label={"TopUp"}/>
    </Toolbar>
);

export const CreateCredit = (props) => (
    <Create  {...props}>
        <SimpleForm  {...props} redirect={redirect} toolbar={<TopUpToolbar/>}>
            <SelectInput
                label={"Method"}
                source="method"
                defaultValue={"Cash"}
                choices={PaymentMethods}
            />
            <TextInput
                source="username"
                label="Username"
            />
            <TextInput
                source="amount"
                label="Amount"
            />
        </SimpleForm>
    </Create>
);

const redirect = () => `/credit`;
