import {
    Datagrid,
    List,
    TextField,
    ReferenceField,
    DateField
} from "react-admin";
//import { TimestampField, BalanceAmountField, DicArrayField } from "../../components/fields";

export const ListUsage = (props) => (
    <List
        title="Usage"
        // sort={{ field: "createdAt", order: "DESC" }}
        {...props}>
        <Datagrid>           
            <DateField source="createdAt" label="Created" showTime/>
            <ReferenceField label="Cost" reference="balance" source="expenseId">
                <TextField source="amount" />
            </ReferenceField>
            <ReferenceField label="Method" reference="balance" source="expenseId">
                <TextField source="method" />
            </ReferenceField>            
            <TextField source="amount" label="Balance" />
        </Datagrid>
    </List>
);