import { users } from "./users";
import { admin, customer, guest } from "./roles";
import { ForgotPasswordPage } from "../forgot";
import { SignUpPage } from "../signup";
import { calls } from "./calls";
import { credit } from "./credits/payments";
import { usage } from "./credits/expenses";
import { balance } from "./credits/balance";
import { Dashboard } from "./dashboard";
import { UserProfile } from "./components/settings";
import SettingsIcon from '@mui/icons-material/Settings';


export function getResources(user) {
    if (user.role === admin) {
        return adminResources;
    }
    if (user.role === customer) {
        return customerResources;
    }
    if (user.role === guest) {
        return guestResources;
    }
}

export const adminResources = [
    Dashboard,
    calls,
    credit,
    usage,
    balance,
    users,
    {
        name: 'userprofile', // Add a unique name for the resource
        icon: SettingsIcon, // Use the same icon as the settings resource or use a different icon if desired
        list: UserProfile, // Use the UserProfile component for the list page
        options: { label: 'Settings' }, // Set a custom label for the resource
    },
];

export const customerResources = [
    Dashboard,
    calls,
    usage,
    {
        name: 'userprofile', // Add a unique name for the resource
        icon: SettingsIcon, // Use the same icon as the settings resource or use a different icon if desired
        list: UserProfile, // Use the UserProfile component for the list page
        options: { label: 'Settings' }, // Set a custom label for the resource
    },
];

export const guestResources = [
    ForgotPasswordPage,
    SignUpPage
];
