import {
    Datagrid,
    List,
    TextField,
    DateField
} from "react-admin";

export const ListUsers = (props) => (
    <List
        title="Users"
        {...props}>
        <Datagrid rowClick="edit">
            <TextField source="username" label="Username" />
            <TextField source="name" label="Name" />
            <TextField source="email" label="Email" />
            <TextField source="phone" label="Phone" />
            <TextField source="role" label="Role" />
            <DateField source="createdAt" label="Created" showTime/>
            <DateField source="updatedAt" label="Updated" showTime/>
        </Datagrid>
    </List>
);