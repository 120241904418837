import * as React from "react";
import { Notification, useNotify } from "react-admin";
import { Paper, Typography, Button, TextField } from "@material-ui/core";
// import { publicUrl } from "./config";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./theme";
import { client } from "./providers/client";
import { useRequestState } from "./resources/hooks/request";
import { Link, useNavigate } from "react-router-dom";
// import { CountrySelectInput } from "./resources/components/fields";


const useStyles = makeStyles({
  forgotPasswordLink: {
    color: "#3f51b5",
    textDecoration: "none",
    textAlign: "center",
    flexGrow: 1,
  },
  main: {
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: "#e6e9f9",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    padding: 16,
    boxSizing: "border-box"
  },
  paper: {
    padding: 16,
    width: "100%",
    maxWidth: 420,
    margin: 8
  },
  verticalMargin: {
    margin: "1em 0"
  }
});

export const SignUpPage = () => {
  const classes = useStyles();
  const register = useRequestState(signUp);
  const [email, setEmail] = React.useState("");
  // const [username, setUsername] = React.useState("");
  const [name, setName] = React.useState("");
  // const [country, setCountry] = React.useState("");
  const [password, setPassword] = React.useState("");

  const navigate = useNavigate();
  const notify = useNotify();

  function handleSubmit(event) {
    event.preventDefault();
    register
      .fetch({ email, name, password })
      .then(data => {
        notify(data.message);
        // Redirect to login page
        navigate('/login');
      })
      .catch(error => {
        notify(error.response?.data?.message || error.message, "error");
      });
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <img
          style={{ display: "block", margin: "0 auto" }}
          src="voice-recognition.png"
          alt="Logo"
          height="160"
        />
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit}>
            <div className={classes.verticalMargin}>
              <TextField
                autoFocus
                name="email"
                label={"Email"}
                value={email}
                fullWidth
                onChange={event => setEmail(event.target.value)}
              />
            </div>

            <div className={classes.verticalMargin}>
              <TextField
                name="name"
                label={"Name"}
                type="name"
                value={name}
                fullWidth
                onChange={event => setName(event.target.value)}
              />
            </div>

            {/* <div className={classes.verticalMargin}
            ></div>
             <CountrySelectInput label="Country" /> 
            <div className={classes.verticalMargin}>
              <TextField
                name="country"
                label={"Country"}
                type="country"
                value={country}
                fullWidth
                onChange={event => setCountry(event.target.value)}
              />
            </div> */}

            {/* <div className={classes.verticalMargin}>
              <TextField
                name="username"
                label={"Username"}
                type="username"
                value={username}
                fullWidth
                onChange={event => setUsername(event.target.value)}
              />
            </div> */}

            <div className={classes.verticalMargin}>
              <TextField
                name="password"
                label={"Password"}
                type="password"
                value={password}
                fullWidth
                onChange={event => setPassword(event.target.value)}
              />
            </div>

            <Button variant="contained" type="submit" color="primary" fullWidth>
              SignUp
            </Button>
          </form>
        </Paper>
        {/* <Typography>
          <a
            className={classes.forgotPasswordLink}
            href={`${publicUrl}/#/login`}
          // target="_blank"
          // rel="noopener noreferrer"
          >
            Login
          </a>
        </Typography> */}
        <div>
          <Typography
            component={Link}
            className={classes.forgotPasswordLink}
            to="/login"
          >
            Login
          </Typography>
        </div>
        <Notification />
      </div>
    </ThemeProvider>
  );
};

function signUp(email, name, password, username) {
  return client
    .post("/portal/users/new", {
      email,
      name,
      password
    })
    .then(response => response.data);
}
