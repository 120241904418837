import CallIcon from '@mui/icons-material/Call';
import { ListCalls } from "./list";
import { ShowCall } from "./show";
// import { CreateCall } from "./create";

export const calls = {
    name: "calls",
    options: {
        label: "Calls"
    },
    icon: CallIcon,
    list: ListCalls,
    // create: CreateCall,
    show: ShowCall
};
