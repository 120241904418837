import * as React from "react";
import { Notification, useLogin, useNotify } from "react-admin";
import { Paper, Typography, Button, TextField } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./theme";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  forgotPasswordLink: {
    color: "#3f51b5",
    textDecoration: "none",
    textAlign: "left",
    flexGrow: 1,
    marginLeft: theme.spacing(10),
  },
  signUpLink: {
    color: "#3f51b5",
    textDecoration: "none",
    textAlign: "right",
    flexGrow: 1,
    marginRight: theme.spacing(10),
  },
  main: {
    // backgroundColor: theme.palette.secondary.main,
    backgroundColor: "#e6e9f9",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    padding: 16,
    boxSizing: "border-box"
  },
  paper: {
    padding: 16,
    width: "100%",
    maxWidth: 420,
    margin: 8
  },
  verticalMargin: {
    margin: "1em 0"
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
  }
});

export const LoginPage = () => {
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const login = useLogin();
  const notify = useNotify();

  function handleSubmit(event) {
    event.preventDefault();
    login({ email, password })
      .catch(error => {
        notify(error.response?.data?.message || error.message, "error");
      });
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <img
          style={{ display: "block", margin: "0 auto" }}
          src="voice-recognition.png"
          alt="Logo"
          height="160"
        />
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit}>
            <div className={classes.verticalMargin}>
              <TextField
                autoFocus
                name="email"
                label={"Email"}
                value={email}
                fullWidth
                onChange={event => setEmail(event.target.value)}
              />
            </div>

            <div className={classes.verticalMargin}>
              <TextField
                name="password"
                label={"Password"}
                type="password"
                value={password}
                fullWidth
                onChange={event => setPassword(event.target.value)}
              />
            </div>

            <Button variant="contained" type="submit" color="primary" fullWidth>
              Sign In
            </Button>
          </form>
        </Paper>
        {/* <Typography>
          <a
            className={classes.signUpLink}
            href={`${publicUrl}/#/signup`}
          // target="_blank"
          // rel="noopener noreferrer"
          >
            SignUp
          </a>
        </Typography> */}
        <div className={classes.root}>
          <Typography
            component={Link}
            className={classes.signUpLink}
            to="/signup"
          >
            SignUp
          </Typography>
          <Typography
            component={Link}
            className={classes.forgotPasswordLink}
            to="/forgot"
          >
            Forgot your Password?
          </Typography>
        </div>
        <Notification />
      </div>
    </ThemeProvider>
  );
};
