// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import React from "react";
import { Admin, Resource, CustomRoutes } from 'react-admin';
// import jsonServerProvider from 'ra-data-json-server';
import { theme } from "./theme";
import { Dashboard } from "./resources/dashboard";
import { authProvider } from "./providers/auth";
import { client } from "./providers/client";
import { dataProvider } from "./providers";
import { LoginPage } from "./login";
import { getResources } from "../src/resources/index";
import { ForgotPasswordPage } from "./forgot";
import { SignUpPage } from "./signup";
import { Route } from "react-router-dom";
import { ThemeProvider } from '@material-ui/styles';

const App = () => (
  <ThemeProvider theme={theme}>
  <Admin
    title="VoiceOTP"
    // theme={theme}
    dashboard={Dashboard}
    authProvider={authProvider(client)}
    dataProvider={dataProvider}
    loginPage={LoginPage}
  >
    <CustomRoutes noLayout>
      <Route path="/forgot" element={<ForgotPasswordPage />} />
      <Route path="/signup" element={<SignUpPage />} />
    </CustomRoutes>

    {user =>
      getResources(user).map(resource => [<Resource {...resource} />])
    }
  </Admin>
  </ThemeProvider>
);

export default App;
