import React from "react";

export function useRequestState(request, defaultValue = null) {
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(defaultValue);

  function fetch(...a) {
    setLoading(true);
    return request(...a)
      .then(v => {
        setValue(v);
        setLoading(false);
        return v;
      })
      .catch(error => {
        setLoading(false);
        return Promise.reject(error);
      });
  }

  return { loading, value, fetch };
}
