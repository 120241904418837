import DataUsage from "@material-ui/icons/DataUsage";
import { ListUsage } from "./list";

export const usage = {
    name: "usage",
    options: {
        label: "Usage"
    },
    icon: DataUsage,
    list: ListUsage,
};