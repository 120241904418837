import { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { fetchOTPData } from '../components/dashboardApi'; // Import your API function to fetch data from MongoDB

export const Dashboard = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const otpData = await fetchOTPData(); // Call your API function to fetch data from MongoDB
        setData(otpData);
      } catch (error) {
        console.error('Error fetching OTP data:', error);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { day: 'numeric', month: 'short' };
    return date.toLocaleDateString('en-US', options);
  };

  return (
    <div>
      <h2>Call Data</h2>
      <BarChart width={600} height={300} data={data.data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tickFormatter={formatDate} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="totalCalls" name="Total Calls" fill="#8884d8" />
        <Bar dataKey="totalSuccess" name="Success" fill="#82ca9d" />
        <Bar dataKey="totalPending" name="Pending" fill="#ffc658" />
        <Bar dataKey="totalNotAnswered" name="NotAnswered" fill="#f04134" />
      </BarChart>
    </div>
  );
};


