import React, { useEffect, useState } from "react";
import { Edit, SimpleForm, TextInput, useGetOne } from "react-admin";
import { backendUrl } from "../../../config";

const UserProfile = (props) => {
  const [userProfile, setUserProfile] = useState(null);
  const userID = localStorage.getItem("user_id");

  useEffect(() => {
    if (userID) {
      fetchUserProfile(userID);
    } else {
      console.log("UserID not found in local storage");
    }
  }, [userID]);

  const fetchUserProfile = async (id) => {
    try {
      const { data, error } = await getUserProfile(id);
      if (data) {
        setUserProfile(data);
      } else if (error) {
        console.error("Error fetching user profile:", error.message);
      }
    } catch (error) {
      console.error("Error fetching user profile:", error.message);
    }
  };

  const getUserProfile = async (id) => {
    try {
      const response = await fetch(`${backendUrl}/portal/userprofile/${id}`); // Replace with your API endpoint
      const data = await response.json();
      return { data };
    } catch (error) {
      return { error };
    }
  };

  if (!userProfile) return <div>Loading...</div>;

  return (
    <Edit {...props} id={userID}>
      <SimpleForm initialValues={userProfile}>
        <TextInput source="apiToken" disabled fullWidth/>
        <TextInput source="username" disabled fullWidth/>
        <TextInput source="name" fullWidth/>
        <TextInput source="email" fullWidth/>
      </SimpleForm>
    </Edit>
  );
};

export { UserProfile };
