import { backendUrl } from "../../config";

export const fetchOTPData = async (user_id) => {
  try {
    const userId = localStorage.getItem("user_id");
    // const response = await fetch(backendUrl+'/portal/calls/fd');
    const response = await fetch(`${backendUrl}/portal/calls/fd/${userId}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching OTP data:', error);
    throw error;
  }
};
