import axios from "axios";
import { backendUrl } from "../config";
import { HttpError } from "react-admin";

export function httpClient(baseURL) {
  const client = axios.create({ baseURL });
  client.interceptors.request.use((request) => {
    const accessToken = localStorage.getItem("token");
    return {
      ...request,
      headers: {
        ...request.headers,
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      },
    };
  });

  return client;
}

export function handleError(error) {
  console.log("error here " + error);
  if (error.response && error.response.data && error.response.data.message) {
    return Promise.reject(
      new HttpError(error.response.data.message, error.response.status)
    );
  }

  return Promise.reject(new HttpError(error.message));
}

export const client = httpClient(backendUrl);
