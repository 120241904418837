import { createTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";

export const theme = createTheme({
  palette: {
    type: 'light',
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  sidebar: {
    width: 240,
    closedWidth: 60,
  },
});
