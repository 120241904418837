import * as React from "react";
import { Notification, useNotify } from "react-admin";
import { Paper, Typography, Button, TextField } from "@material-ui/core";
// import { publicUrl } from "./config";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./theme";
import { useRequestState } from "./resources/hooks/request";
import { client } from "./providers/client";
import { Link , useNavigate} from "react-router-dom";

const useStyles = makeStyles({
  forgotPasswordLink: {
    color: "#3f51b5",
    textDecoration: "none",
    textAlign: "center",
    flexGrow: 1,
  },
  main: {
     // backgroundColor: theme.palette.secondary.main,
    backgroundColor: "#e6e9f9",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    padding: 16,
    boxSizing: "border-box"
  },
  paper: {
    padding: 16,
    width: "100%",
    maxWidth: 420,
    margin: 8
  },
  verticalMargin: {
    margin: "1em 0"
  }
});

export const ForgotPasswordPage = () => {
  const classes = useStyles();
  const emailReset = useRequestState(resetPassword);
  const [email, setEmail] = React.useState("");
  const notify = useNotify();
  const navigate = useNavigate();

  // const forgot = useLogin();
  // function handleSubmit(event) {
  //   event.preventDefault();
  //   forgot({ email }).catch(error => {
  //     notify(error.response?.data?.message || error.message, "error");
  //   });
  // }

  function handleSubmit() {
    emailReset
      .fetch(email)
      .then(data => {
        notify(data.message);
        // Redirect to login page
        navigate('/login');
      })
      .catch(error => {
        notify(error.response?.data?.message || error.message, "error");
      });
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <img
          style={{ display: "block", margin: "0 auto" }}
          src="voice-recognition.png"
          alt="Logo"
          height="160"
        />
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit}>
            <div className={classes.verticalMargin}>
              <TextField
                type="email"
                autoFocus
                name="email"
                label={"Email"}
                value={email}
                fullWidth
                onChange={event => setEmail(event.target.value)}
              />
            </div>

            <Button variant="contained" type="submit" color="primary" fullWidth>
              Reset
            </Button>
          </form>
        </Paper>
        {/* <Typography>
          <a
            className={classes.forgotPasswordLink}
            href={`${publicUrl}/#/login`}
            // target="_blank"
            rel="noopener noreferrer"
          >
            Login
          </a>
        </Typography> */}
        <div>
          <Typography
            component={Link}
            className={classes.forgotPasswordLink}
            to="/login"
          >
            Login
          </Typography>
        </div>
        <Notification />
      </div>
    </ThemeProvider>
  );
};

function resetPassword(email) {
  return client
    .post("auth/reset-password", {
      email
    })
    .then(response => response.data);
}
