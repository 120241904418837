import {
    Datagrid,
    List,
    TextField,
    DateField
} from "react-admin";

export const ListCalls = (props) => (
    <List
        title="Calls"
        {...props}>
        <Datagrid>
            <TextField source="phoneNumber" />
            <TextField source="companyName" />
            <TextField source="otpCode" />
            <TextField source="status" label="Status" />
            <TextField source="amount" label="Cost" />
            <DateField source="createdAt" label="Created" showTime />
        </Datagrid>
    </List>
);
